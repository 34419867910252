<template>
  <div class="main-about-container">
    <div class="about-hero">
      <img src="@/assets/img/about-hero.svg" alt="">
    </div>

    <div class="about-body">
      <div class="inner">
        <p><img src="@/assets/img/about-headshot.png" alt="">I landed in this space by way of my education. I had intended to study medicine, so I started with a major in Kinesiology. But then, during my undergrad degree, I took a nutrition and physiology course and I was mesmerized. I’m a big science nerd and I fell in love with the science behind nutrition. I found it particularly interesting that our bodies really are largely shaped by genetics and metabolic pathways—and that this is the opposite of the Western message that you can change your body by changing what you put into it. I also love how culture, religion, social determinants of health, education and more intersect with nutrition. This is where you get to know people, and it’s one of the aspects I love most about my work. So, long story short, I finished my degree and changed my path to nutrition.</p>
        <p>This decision has turned out to be one of the most impactful and meaningful of my life. The
        work is both challenging and gratifying, and when I’m able to help a client, it reaffirms my
        choice. This work isn’t just about food; it’s also about the role mental health plays in our
        relationship with nutrition. Helping people to understand this and factor it into their recovery is
        important and, for me,&nbsp;very&nbsp;fulfilling.</p>

        <p>In addition to my passion for the nutrition field, I’m also proud to be a woman of colour in this
        space. The perspectives of people of colour are rarely reflected in nutrition education and
        professional practice, and this needs to change. The call to diversify dietetics will hopefully
        mean that one day everyone will be able to find a professional who represents them, who looks
        like them, and who can tailor their discussions and treatment to reflect different experiences.</p>

        <p>Outside of my work life, I am a busy wife and mom to two little ones. I love to travel near and
        far, have family meals, dance in my kitchen and crochet Amigurumi. Growing up on the east
        coast, being near the water will forever be my happy place.</p>
      </div>
      <div class="col inner">
      </div>
    </div>

    <div class="education-hero">
      <img src="@/assets/img/education.svg" alt="">
    </div>

    <div class="about-body">
      <div class="inner education-inner">
        <ul>
          <li>Certified Intuitive Eating Counselor</li>
          <li>Principles of Family-Based Treatment for Adolescent Eating Disorders</li>
          <li>Intro to Cognitive Behavioral Therapy Level 2 - McMaster University</li>
          <li>Registered Dietitian - College of Dietitians of Ontario</li>
          <li>Certified Exercise Physiologist - Canadian Society for Exercise Physiology</li>
        </ul>
      </div>
    </div>
    <Signup></Signup>
  </div>
</template>

<script>

import Signup from '@/components/Signup.vue'

export default {
  name: 'About',
  components: {
    Signup
  }
}
</script>

<style lang="stylus" scoped>
  .main-about-container
    padding-top 102px
    background-color #F3F0E8

  .about-hero
    background url('~@/assets/img/about-hero.jpg') center center / cover no-repeat
    padding 80px 20px
    text-align center
    position relative

  .about-body
    padding 90px 0

    p
      margin-bottom 20px

    img
      width 46%
      margin 0 20px 20px 0
      float left

    .row.inner p
      width 50%

    .col.inner
      padding 60px 0 0

    ul
      list-style-type disc

      li
        font-size 16px
        font-weight 300
        color #2a1c2d
        font-family 'Montserrat', sans-serif
        margin-bottom 20px

  .education-hero
    background url('~@/assets/img/education-bg.jpg') center center / cover no-repeat
    padding 130px 0
    text-align center
    position relative

  .education-inner
    max-width 900px

  @media (max-width: 1000px)
    .main-about-container
      padding-top 70px

    .about-body
      padding 40px 0

      img
        margin 0 auto
        width 100%

      .row.inner
        flex-direction column !important
        width 70%

        p
          width 100%

    .education-hero
      padding 80px 30px

</style>
